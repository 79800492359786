import { Box, Flex } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const Main = ({ children }: Props) => {
  const styles = useMultiStyleConfig('Main', {});

  return (
    <Box as="main" sx={styles.Main} role="main">
      <Flex wrap="wrap">{children}</Flex>
    </Box>
  );
};
